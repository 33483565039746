.LoginPanel{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    max-width: 50vw;
}

.LoginPanel > input{
    padding: 3px;
    height: 20px;
    margin: 3px;
    border-radius: 5px;
}

.LoginLogo{
    max-height: 200px;
    min-height: 100px;
    height: 40vmin;
    margin: 5vh;
    pointer-events: none;
  }